.gc-cc-wrapper {
    font-size: 16px;
    .gc-cc-subtext {
        text-align: center; }
    .gc-cc-selector-wrapper {
        display: flex; }
    .gc-cc-selector {
        cursor: pointer;
        margin-top: 40px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 20px;
        display: flex;
        .gc-cc-title {
            font-weight: bold; }
        .gc-cc-inputs {
            .checkbox {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0!important;
                width: 0!important; }
            .checkmark {
                height: 15px;
                width: 15px;
                border: 1px solid #009B54;
                background-color: #eee;
                margin-top: 4px;
                margin-right: 15px;
                &::after {
                    left: 9px;
                    top: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg); } }
            input:checked ~ .checkmark {
                background-color: #009B54;
                border: 1px solid #eee; } } }
    .gc-cc-btns {
        margin-top: 40px;
        display: flex;
        justify-content: center; }
    .grey {
        background-color: #eee;
        color: #707070; }
    .gc-cc-link {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center; }
    .gc-cc-link {
        a {
            color: inherit; } } }
