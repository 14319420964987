.room-description {
    font: normal normal 300 1.875vw Roboto Condensed;
    font-size: 18px;
    text-align: justify;
    width: 500px;
    max-height: 400px;
    // color: #fff
    // background: rgba(0, 0, 0, 0.8)
    padding: 0 20px;
    z-index: 9; }

.room-description-wrapper-v1 {
    font: normal normal 300 16px Roboto Condensed;
    font-family: Roboto Condensed;
    width: 500px;
    height: 50%;
    position: absolute;
    top: 30%;
    left: 150px;
    color: black;
    background: white;
    padding: 20px;
    z-index: 10; }
.room-description-close-v1 {
    margin-bottom: 10px;
    font-weight: bold;
    text-align: right;
    cursor: pointer; }
.room-description-v1 {
    overflow: auto;
    height: 95%;
    padding-right: 5px; }

.room-description-v1::-webkit-scrollbar {
    width: 10px; }

.room-description-v1::-webkit-scrollbar-thumb {
    background-color: rgba(179, 174, 174, 0.8); }

.hotspot {
    background: url('../assets/icons/info-white-bg.png') no-repeat;
    width: 54px;
    height: 54px;
    .in {
        display: none; }
    .out {
        display: none; } }

.navigation-arrows {
    a {
        cursor: pointer; }
    .gc-help-text {
        text-align: center;
        color: #009B53;
        background-color: rgba(255, 255, 255, 0.4);
        margin-bottom: 10px; }
    .left-arrow {
        width: 114px;
        height: 114px;
        left: 20px;
        position: absolute;
        top: 50%;
        z-index: 10; }
    .right-arrow {
        right: 20px;
        position: absolute;
        top: 50%;
        z-index: 10;
        width: 114px;
        height: 114px; } }
.icon-360-cower {
    position: absolute;
    left: 50%;
    bottom: 0;
    .icon-360 {
        position: relative;
        left: -50%;
        width: 100px;
        height: 100px; } }
.title-message {
    text-decoration: none;
    color: #939393;
    font-size: 30px;
    &:hover {
        color: #009B54;
        font-weight: bold; }
    &.active {
        color: #009B54;
        font-weight: bold; } }
