.pnlm-tooltip {
    span {
        visibility: visible !important; } }
.pnlm-container {
    height: 100%!important; }

.pnlm-render-container {
    .gc-help-text {
        text-align: center;
        color: #009B53;
        background-color: rgba(255, 255, 255, 0.8);
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        max-width: 150px; }
    .pnlm-hotspot {
        height: 55px;
        width: 55px; }
    .tooltipcss {
        text-align: center; }
    .pnlm-sprite {
        background: url('../assets/icons/info-white-bg.png') no-repeat;
        width: 54px;
        height: 54px; }
    .pnlm-hotspot.pnlm-info {
        background-position: inherit; } }
