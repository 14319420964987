.gc-widget {
    width: 680px;
    box-shadow: 0px 0px 10px grey;
    .head {
        width: 100%;
        height: 50px;
        padding: 25px 35px 10px 35px;
        background: #1A5CA3;
        display: block;
        .title {
            font-size: 24px;
            color: #FFF;
            float: left; }
        .arrow {
            font-size: 24px;
            color: #FFF;
            float: right;
            cursor: pointer; } }
    .body {
        width: 100%;
        padding: 25px 35px 10px 35px;
        height: 350px;
        background: #FFF; } }

.gc-widget.animation {
    position: absolute;
    -webkit-animation: easeOutAnimation 1.6s normal ease-out;
    animation: easeOutAnimation 1.6s normal ease-out;
    @keyframes easeOutAnimation {
            0% {
                bottom: -500px; }
            100% {
                bottom: 0px; } } }
