
.exit-window {
    width: 550px!important; }
.gc-modal-auto-with {
    width: inherit !important; }
.gc-modal {
    overflow: hidden;
    font: normal normal 300 1.875vw Roboto Condensed;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    outline: none;
    width: 25%;
    .gc-modal-iframe {
        padding: 50px 20px 15px 20px; }
    .gc-modal-nopadding {
        margin-top: -50px; }
    .gc-modal-text {
        padding: 0px 20px 15px 00px;
        font-size: 15px; }
    &.gc-modal-mobile {
        width: 100%;

        min-height: 25%;
        box-shadow: 0px 0px 10px grey;
        padding: 10px;
        text-align: center; }
    .flex-container {
        display: flex;
        flex-flow: row;
        list-style-type: none;
        font-size: 22px;
        .flex-item {
            text-align: center; }
        .gc-midle {
            padding: 18px 10px; }
        .gc-green-button {
            border-radius: 5px;
            background-color: #009B54;
            padding: 18px 20px 0px 20px;
            min-width: 160px;
            height: 40px;
            text-transform: uppercase;
            color: #ffffff; } }
    .gc-modal-buttons {
        font-size: 30px;
        display: flex; }

    .gc-modal-window {
        &.exit-form {
            text-align: center; }
        .sticky-right {
            position: sticky;
            top: 0px;
            margin-right: 10px;
            float: right;
            z-index: 10;
            padding-top: 5px; }
        .modal-room-title {
            position: sticky;
            top: 0px;
            float: left;
            background: #ffffff;
            color: #009B54;
            z-index: 5;
            padding-top: 10px; }
        .modal-room-title-wrapper {
            display: flex;
            h2 {
                flex-grow: 1; }
            img {
                padding-top: 30px;
                width: 30px;
                height: 30px; } }
        .room-description-text {
            overflow: auto;
            height: 250px;
            padding-right: 30px;
            padding-bottom: 30px; }
        .gc-modal-exit {
            float: right;
            margin: 10px;
            &.absolute-right {
                position: absolute;
                right: 10px;
                top: 10px; } }
        .gc-modal-title {
            color: #009B54;
            text-align: center;
            font-size: 40px;
            text-transform: uppercase;
            margin-top: 20px;
            clear: both; }

        .gc-modal-content {
            color: #707070;
            white-space: pre-line;
            font-size: 30px;
            .gc-modal-exit-wrapper {
                font-size: 18px;
                margin-top: 20px;
                height: 50px;
                vertical-align: middle;
                span {
                    padding-left: 10px; } }
            .modal-exit-icon {
                vertical-align: middle; }
            .feedback-form {
                font-size: 18px;
                width: 95%;
                color: #666666;
                margin: 0 15px;
                text-align: justify;
                height: auto;
                .action {
                    text-align: center;
                    .form {
                        .gdpr-section {
                            display: flex;
                            margin-bottom: 10px;
                            .input-gdpr {
                                margin-top: 0; }
                            .input-checkbox {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0!important;
                                width: 0!important; }
                            .checkmark {
                                height: 25px;
                                width: 75px;
                                background-color: #eee;
                                margin-right: 5px;
                                &::after {
                                    left: 9px;
                                    top: 5px;
                                    width: 5px;
                                    height: 10px;
                                    border: solid white;
                                    border-width: 0 3px 3px 0;
                                    -webkit-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    transform: rotate(45deg); } }
                            input:checked ~ .checkmark {
                                background-color: #009B54; } }
                        .buttons {
                            margin-bottom: 10px; }
                        .input-title {
                            color: #666666;
                            margin: 12px 0 3px 0;
                            text-align: left;
                            font-weight: bold; }
                        input {
                            width: 100%;
                            border: 1px solid var(--unnamed-color-e8e8e8);
                            background: #E8E8E84D 0% 0% no-repeat padding-box;
                            border: 1px solid #E8E8E8;
                            border-radius: 5px;
                            opacity: 1;
                            height: 40px;
                            padding-left: 5px; }
                        input:focus {
                            outline: none !important; }
                        textarea {
                            width: 100%;
                            padding: 16px;
                            background: #E8E8E84D 0% 0% no-repeat padding-box;
                            border: 1px solid #E8E8E8;
                            margin-bottom: 16px;
                            font-size: 14px;
                            box-sizing: border-box;
                            height: 150px; }

                        .gc-green-button {
                            font-size: 16px!important;
                            cursor: pointer;
                            font: normal normal 300 1.875vw Roboto Condensed;
                            border-radius: 5px;
                            background-color: #009B54;
                            padding: 0px 20px;
                            height: 40px;
                            text-transform: uppercase;
                            color: #ffffff;
                            border: none;
                            &.button-disabled {
                                background: #F8F8F8;
                                cursor: default;
                                color: #009B54; }
                            &.button-back {
                                margin-right: 10px;
                                padding: 10px 20px; } }

                        .error-message {
                            text-align: left;
                            color: red; } } } } }

        .gc-modal-slider {
            float: left;
            width: 400px;
            // clear: both
            .slide {
                display: flex;
                .image {
                    flex: grow;
                    margin-left: 25px;
                    margin-top: 10px;
                    padding-right: 15px;
                    overflow: hidden;
                    height: 360px; } }
            .slick-arrow {
                &.slick-next {
                    right: 60px; }
                &.slick-next:before {
                    font-size: 41px;
                    content: url('../assets/icons/icon-slider-right.png'); }
                &.slick-prev {
                    left: 40px;
                    z-index: 50; }
                &.slick-prev:before {
                    font-size: 41px;
                    content: url('../assets/icons/icon-slider-left.png'); } } }
        .slide-content {
            float: left;
            width: 320px;
            color: #666666;
            .slide-title {
                text-align: center;
                font-size: 25px;
                margin: 20px 43px 20px 0;
                font-weight: bold;
                color: #009B54; }
            .slide-text {
                padding-right: 5px;
                font-size: 16px;
                letter-spacing: 0px;
                text-align: justify;
                height: 220px;
                overflow: auto; }
            .slide-text::-webkit-scrollbar {
                width: 5px; }
            .slide-text::-webkit-scrollbar-thumb {
                background-color: rgba(179, 174, 174, 0.8); }
            .gc-green-button {
                margin-top: 20px;
                padding: 7px 20px;
                min-width: auto;
                height: auto;
                border: none;
                position: absolute;
                bottom: 19px;
                right: 0; } }

        .download-content {
            font-size: 14px;
            width: 95%;
            color: #666666;
            margin: 35px 15px 0 15px;
            text-align: justify;
            height: 360px;
            h2 {
                text-align: left;
                font-size: 22px;
                font-weight: bold; }
            .action {
                text-align: center;
                .caption {
                    color: #009B54;
                    text-transform: uppercase;
                    font-size: 30px;
                    width: 110px;
                    background-position: center left;
                    width: 50%;
                    margin: 0 auto;
                    .caption-title {
                        float: left;
                        margin: -4px 0 0 10px; }
                    .caption-img {
                        float: left;
                        width: 30px;
                        img {
                            width: 100%; } } }
                .text {
                    clear: both; }
                .download-form {
                    .input-title {
                        color: #666666;
                        margin: 12px 0 3px 0;
                        text-align: left;
                        font-weight: bold;
                        font-size: 16px; }
                    input {
                        width: 100%;
                        border: 1px solid var(--unnamed-color-e8e8e8);
                        background: #E8E8E84D 0% 0% no-repeat padding-box;
                        border: 1px solid #E8E8E8;
                        border-radius: 5px;
                        opacity: 1;
                        height: 30px; }
                    .gc-green-button {
                        margin-top: 20px;
                        padding: 7px 20px;
                        min-width: auto;
                        height: auto;
                        border: none; }
                    .error-message {
                        text-align: left;
                        color: {} } } } }
        .download-controlls {
            margin: 0 15px;
            text-align: left;
            .icons {
                float: left;
                .icon {
                    margin: 0 10px;
                    img {
                        width: 25px; } } }
            .buttons {
                float: right;
                .gc-modal-buttons {
                    font-size: initial;
                    margin-top: -5px;
                    .gc-green-button {
                        padding: 7px 20px;
                        min-width: auto;
                        height: auto; } } } } } }

.gc-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(103, 103, 103, 0.75); }
