.gc-testimonials-logos {
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0px;
    font-family: Roboto Condensed;
    font-weight: normal;

    .slick-wrapper {
        display: block;
        .slick-dots {
            text-align: right;
            li {
                button:before {
                    color: #1A5CA3;
                    pacity: 0.5;
                    font-size: 14px; } }
            .slick-active {
                button:before {
                    opacity: 1; } } } }

    .testimonial {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 287px;
        .image {
            padding-left: 50px;
            padding-right: 50px;
            text-align: center;
            img {
                max-width: 200px;
                max-height: 100px; } } } }
