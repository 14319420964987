.gc-btn {
    cursor: pointer;
    border-radius: 5px;
    background-color: #009B54;
    padding: 5px 20px 5px 20px;
    min-width: 160px;
    height: 40px;
    font-size: 18px;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    border: 0;
    margin: 5px; }
