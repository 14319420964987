.gc-layout-desktop {
    width: 100%;
    height: 100%;
    position: fixed;

    .gc-header {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 110px;
        z-index: 10;
        background: #fff;
        box-shadow: 0px 0px 10px grey;

        div {
            flex-grow: 1;

            &.right {
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                width: 40%; }

            &.left {
                text-align: left;
                display: inline-flex;
                align-items: center;
                width: 40%;
                .welcome-message {
                    cursor: pointer;
                    margin-left: 10px;
                    width: 91px; } }
            &.center {
                text-align: center;
                width: 20%; }

            img {
                padding-right: 30px; }

            img.gc-header-logo {
                padding-right: 0; }

            img.gc-header-icon-flag {
                width: 36px;
                height: 36px;
                padding-left: 40px;
                padding-right: 15px; }

            img.gc-header-icon-qa {
                width: 42px;
                height: 42px;
                padding-right: 50px; }

            img.gc-header-icon-meeting {
                width: 36px;
                height: 36px; }

            img.gc-header-icon-phone {
                width: 36px;
                height: 33px; }

            img.gc-header-icon-email {
                width: 39px;
                height: 36px;
                padding-right: 40px; } }

        span {
            color: #666666;
            font: normal normal 300 1.875vw Roboto Condensed;
            letter-spacing: 0px; } }

    .gc-stage {
        display: flex;
        height: calc(100% - 220px);
        text-align: center;
        justify-content: space-between; }

    .gc-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        padding-top: 10px;
        padding-bottom: 5px;

        div {
            flex-grow: 1;
            width: 33%;

            &.right {
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                span {
                    cursor: pointer;
                    &:hover {
                        color: #009B54;
                        font-weight: bold; } }
                .active-green {
                    color: #009B54;
                    font-weight: bold; } }
            &.left {
                text-align: left;
                display: inline-flex;
                align-items: center;
                span {
                    cursor: pointer;
                    &:hover {
                        color: #009B54;
                        font-weight: bold; } }
                .active-green {
                    color: #009B54;
                    font-weight: bold; } }

            &.center {
                text-align: left;
                display: inline-flex;
                span {
                    cursor: pointer;
                    &:hover {
                        color: #009B54;
                        font-weight: bold; } }
                .active-green {
                    color: #009B54;
                    font-weight: bold; } }

            img.gc-header-icon-references {
                width: 55px;
                height: 55px;
                padding-left: 40px;
                padding-right: 15px; }

            img.gc-header-icon-360 {
                width: 81px;
                height: 81px; }

            img.gc-header-icon-feedback {
                width: 46px;
                height: 55px;
                padding-right: 40px;
                padding-left: 15px; } }
        span {
            color: #666666;
            font: normal normal 300 1.5vw Roboto Condensed;
            letter-spacing: 0px; } } }
