.gc-contact {
    position: absolute;
    width: 500px;
    z-index: 1;
    right: 60px;
    top: 110px;
    background: #FFF;
    box-shadow: 0px 0px 10px grey;
    .body {
        padding: 0;
        height: 70vh;
        max-height: 600px;
        overflow: auto;
        .title {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            margin: 15px 0px 15px 0px;
            color: #1A5CA3; }
        .subtitle {
            text-align: center;
            font-size: 22px;
            font-weight: 200;
            margin: 15px 0px 15px 0px;
            color: #676767; }
        .panel {
            margin-top: 1px;
            padding: 20px 15px 20px 15px;
            display: flex;
            background-color: #1A5CA3; }
        .contact {
            padding: 5px 15px 0px 30px;
            color: #fff;
            font-size: 18px;
            .name {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px; }
            .desc {
                margin-bottom: 15px;
                .role {}
                .company {} }
            .e-mail {
                display: flex;
                .icon {
                    display: block;
                    margin-right: 10px;
                    padding-top: 2px; } }
            a {
                color: #fff;
                text-decoration: none; }
            .phone {
                cursor: pointer;
                display: flex;
                margin-top: 5px;
                .icon {
                    display: block;
                    margin-right: 10px;
                    padding-top: 2px; } } } } }

.gc-contact.animation {
    position: absolute;
    -webkit-animation: easeOutAnimationTop 1.6s normal ease-out;
    animation: easeOutAnimationTop 1.6s normal ease-out;
    @keyframes easeOutAnimationTop {
        0% {
            top: -500px; }
        100% {
            top: 110px; } } }
