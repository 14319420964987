.gc-exit-wrapper {
    position: absolute;
    z-index: 1000;
    left: 0px;
    top: 150px;
    background: #1A5CA3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 0 10px 15px;
    box-shadow: 0px 3px 6px #00000029;

    img.gc-header-icon-exit {
        width: 40px;
        height: 40px;
        padding-left: 10px;
        padding-right: 5px;
        float: left; }

    .gc-exit-text {
        float: left;
        width: 160px;
        // display: none
        font-size: 19px;
        font-weight: bold;
        padding: 0;
        color: #ffffff; } }
