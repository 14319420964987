@import 'App';
@import 'Fonts';
@import 'Layouts/Desktop';
@import 'Sketch-fab';
@import 'Testimonials';
@import 'Testimonials-logos';
@import 'Problems';
@import 'QA';
@import 'Widget';
@import 'Contact';
@import 'Exit';
@import 'Modal';
@import 'Faq';
@import 'Certificates';
@import 'Legend';
@import 'Room';
@import 'Pannellum';
@import 'Buttons';
@import 'CookieConsent';

// Modal
@import 'Modal/Mobile';

body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif {}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace {} }

strong {
    font-weight: bold; }
