.gc-modal-window {
    .gc-modal-mobile {
        padding: 35px 10px 35px 10px;
        .subtitle {
            color: #707070;
            font-size: 22px;
            padding: 10px 0px; }
        .title {
            color: #009B54;
            font-size: 30px;
            padding: 10px 0px; } } }
