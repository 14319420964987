.gc-problems, .gc-qa {
    position: absolute;
    z-index: 2;
    right: 70px;
    bottom: 0px;
    font-family: Roboto Condensed;
    font-weight: normal;
    .body {
        overflow: auto;
        .button-back {
            text-decoration: underline;
            cursor: pointer;
            color: #1A5CA3; } }
    .problem {
        margin: 15px 0px 15px 0px;
        display: flex;
        font-size: 20px;
        cursor: pointer;
        .icon {}
        .text {
            margin: 0px 0px 0px 25px;
            p {
                margin-top: 0; } }
        .text:hover {
            color: #009B54; } }
    .view {
        height: 300px;
        margin-bottom: 15px;
        overflow: auto;
        .title {
            display: flex;
            margin: 15px 0px 10px 0px;
            font-size: 20px;
            overflow: auto;
            .icon {}
            .problem {
                margin: 3px 0px 0px 25px;
                cursor: inherit; } }
        .text {
            font-size: 20px;
            font-weight: 300; } } }
