.gc-testimonials {
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0px;

    .slick-wrapper {
        display: block;
        .slick-dots {
            text-align: right;
            li {
                button:before {
                    color: #1A5CA3;
                    pacity: 0.5;
                    font-size: 14px; } }
            .slick-active {
                button:before {
                    opacity: 1; } } } }

    .testimonial {
        display: flex;
        .image {}
        .review {
            flex: grow;
            overflow: auto;
            margin-left: 25px;
            margin-top: 10px;
            padding-right: 15px;
            overflow: auto;
            height: 300px;
            .stars {
                padding-bottom: 23px; }
            .title {
                margin: 0px 0px 20px 0px;
                font-size: 22px;
                color: #000;
                font-weight: bold; }
            .user {
                display: flex;
                margin: 8px 0px 8px 0px;
                img {
                    margin-right: 10px; }
                div {
                    margin-top: -1px;
                    color: #009B54; } }
            .time {
                display: flex;
                margin: 8px 0px 8px 0px;
                img {
                    margin-right: 10px; }
                div {
                    margin-top: -1px; } }
            .text {
                font-size: 14px;
                color: #333333; }
            .text-short {
                font-size: 14px;
                color: #333333;
                background: -webkit-linear-gradient(#333333, white);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; }
            .more {
                display: flex;
                cursor: pointer;
                color: #009B54;
                margin-top: 10px;
                margin-bottom: 20px;
                div {
                    float: left; }
                img {
                    margin-left: 10px;
                    margin-top: 5px; } } } } }
